var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_vm.activeComponent === 'banner' ? _c('section', {
    staticClass: "banner"
  }, [_vm._m(0), _c('div', {
    staticClass: "banner-main"
  }, [_vm._m(1), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.activeComponent = 'question';
      }
    }
  }, [_vm._v("前往測驗")])])]) : _vm._e(), _vm.activeComponent === 'question' ? _c('section', {
    staticClass: "question"
  }, [_c('h2', [_c('img', {
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/question/roll.svg"),
      "alt": "roll"
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.question[_vm.activeQuestion].title) + " " + _vm._s() + " ")])]), _c('div', {
    staticClass: "question-wrap"
  }, [_c('div', {
    staticClass: "question-main"
  }, [_c('img', {
    attrs: {
      "src": _vm.question[_vm.activeQuestion].image,
      "alt": "image"
    }
  }), _c('ul', _vm._l(_vm.questionStatus.length === 2 ? _vm.question[_vm.activeQuestion].items.filter(function (item) {
    return _vm.questionStatus.includes(item.value);
  }) : _vm.question[_vm.activeQuestion].items, function (item, index) {
    return _c('li', {
      key: item.id,
      on: {
        "click": function ($event) {
          return _vm.nextQuestion(item);
        }
      }
    }, [_vm._v(_vm._s(_vm.questionItemName[index]) + ". " + _vm._s(item.text))]);
  }), 0)])])]) : _vm._e(), _vm.activeComponent === 'form' ? _c('section', {
    staticClass: "form"
  }, [_c('form', [_vm._m(2), _c('div', {
    staticClass: "signUp-form"
  }, [_c('div', {
    staticClass: "signUp-input"
  }, [_vm._m(3), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "姓名 (必填)"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "signUp-input"
  }, [_vm._m(4), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone,
      expression: "form.phone"
    }],
    attrs: {
      "type": "text",
      "placeholder": "手機 (必填)"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "phone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "signUp-input"
  }, [_vm._m(5), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    attrs: {
      "type": "text",
      "placeholder": "信箱 (必填)"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "privacy"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.privacyPolicyCheck,
      expression: "privacyPolicyCheck"
    }],
    attrs: {
      "id": "privacy",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.privacyPolicyCheck) ? _vm._i(_vm.privacyPolicyCheck, null) > -1 : _vm.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.privacyPolicyCheck = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.privacyPolicyCheck = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.privacyPolicyCheck = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "privacy"
    }
  }, [_vm._v(" 我同意 WUWOW JUNIOR 線上英文 "), _c('router-link', {
    attrs: {
      "to": {
        path: _vm.privacyUrl
      },
      "target": "_blank"
    }
  }, [_vm._v("隱私權政策")])], 1)]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.sendForm();
      }
    }
  }, [_vm._v("手刀領取")]), _vm._m(6)])])]) : _vm._e(), _vm.activeComponent === 'result' ? _c('section', {
    staticClass: "result"
  }, [_c('div', {
    staticClass: "result-wrap"
  }, [_c('div', {
    staticClass: "result-imag"
  }, [_c('img', {
    attrs: {
      "src": _vm.result[_vm.resultImage].web,
      "alt": "results"
    }
  }), _vm._m(7)])]), _c('div', {
    staticClass: "result-step"
  }, [_vm._m(8), _vm._m(9), _c('button', {
    attrs: {
      "type": "button",
      "disabled": _vm.showCopySuccess
    },
    on: {
      "click": function ($event) {
        return _vm.copyUrl();
      }
    }
  }, [!_vm.showCopySuccess ? _c('span', [_vm._v("複製測驗連結")]) : _vm._e(), _vm.showCopySuccess ? _c('span', [_vm._v("✓複製成功")]) : _vm._e()]), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "copyTextId",
      "type": "text"
    },
    domProps: {
      "value": _vm.shareUrl
    }
  })])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://www.wuwowjr.tw/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/header/wuwow-jr-logo-v2.png"),
      "alt": "wuwow-jr-logo"
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', [_c('span', [_vm._v("玩就抽1年份Disney+!")]), _c('img', {
    staticClass: "banner-title",
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/banner/banner-title.png"),
      "alt": "玩就抽1年份Disney+!"
    }
  }), _c('img', {
    staticClass: "banner-subTitle",
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/banner/banner-subTitle.png"),
      "alt": "迪士尼 1 代經典角色，快來測驗，你最適合哪位經典人物的學習方式?"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', [_vm._v(" 報名領取 "), _c('br'), _c('span', [_vm._v(" 測驗結果 ")]), _vm._v(" 和 "), _c('span', [_vm._v(" 免費英文健檢 ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/form/people.png"),
      "alt": "姓名"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/form/phone.png"),
      "alt": "手機"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/form/mail.png"),
      "alt": "信箱"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', [_vm._v("0800 885 315")]), _vm._v(" 撥打專線，立即有專人為您服務 ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert-tip"
  }, [_c('p', {
    staticClass: "animate__animated animate__pulse animate__infinite infinite"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/event/psychological_test_dizney_characters/result/finger.png"),
      "alt": "finger"
    }
  }), _vm._v(" 手機平板用戶 請長按儲存圖片 "), _c('br'), _vm._v(" 電腦用戶 請右鍵儲存圖片 ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "result-title"
  }, [_c('h2', [_vm._v(" 四步驟 "), _c('span', [_vm._v("抽一年份Disney+")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('div', {
    staticClass: "tag"
  }, [_vm._v("第一步")]), _c('p', [_vm._v(" 儲存並分享你的 "), _c('span', [_vm._v("心理測驗")]), _vm._v(" 結果至 "), _c('span', [_vm._v("限時動態")])])]), _c('li', [_c('div', {
    staticClass: "tag"
  }, [_vm._v("第二步")]), _c('p', [_vm._v(" 限時動態 "), _c('span', [_vm._v("公開")]), _vm._v(" 標記 "), _c('span', [_vm._v("@wuwow.online")])])]), _c('li', [_c('div', {
    staticClass: "tag"
  }, [_vm._v("第三步")]), _c('p', [_c('span', [_vm._v("附上")]), _vm._v(" 迪士尼 "), _c('span', [_vm._v("心理測驗連結")])])]), _c('li', [_c('div', {
    staticClass: "tag"
  }, [_vm._v("第四步")]), _c('p', [_vm._v(" 並 "), _c('span', [_vm._v("寫下你的學習方式")]), _vm._v(" ，例如：我都在 "), _c('br'), _vm._v(" WUWOW JR 學英文！ ")])])]);

}]

export { render, staticRenderFns }