<template>
  <main>
    <section v-if="activeComponent === 'banner'" class="banner">
      <header>
        <ul>
          <li><a href="https://www.wuwowjr.tw/"><img
            src="@/assets/event/psychological_test_dizney_characters/header/wuwow-jr-logo-v2.png"
            alt="wuwow-jr-logo"
          ></a>
          </li>
        </ul>
      </header>
      <div class="banner-main">
        <h1>
          <span>玩就抽1年份Disney+!</span>
          <img
            class="banner-title"
            src="@/assets/event/psychological_test_dizney_characters/banner/banner-title.png"
            alt="玩就抽1年份Disney+!"
          >
          <img
            class="banner-subTitle"
            src="@/assets/event/psychological_test_dizney_characters/banner/banner-subTitle.png"
            alt="迪士尼 1 代經典角色，快來測驗，你最適合哪位經典人物的學習方式?"
          >
        </h1>
        <button type="button" @click="activeComponent = 'question'">前往測驗</button>
      </div>
    </section>
    <section v-if="activeComponent === 'question'" class="question">
      <h2>
        <img
          src="@/assets/event/psychological_test_dizney_characters/question/roll.svg"
          alt="roll"
        >
        <p>
          {{ question[activeQuestion].title }}
          {{  }}
        </p>
      </h2>
      <div class="question-wrap">
        <div class="question-main">
          <img :src="question[activeQuestion].image" alt="image">
          <ul>
            <li
              v-for="(item, index) in questionStatus.length === 2 ? question[activeQuestion].items.filter((item) => questionStatus.includes(item.value)) : question[activeQuestion].items"
              :key="item.id"
              @click="nextQuestion(item)"
            >{{ questionItemName[index] }}. {{ item.text }}</li>
          </ul>
        </div>
      </div>
    </section>
    <section v-if="activeComponent === 'form'" class="form">
      <form>
        <h2>
          報名領取
          <br>
          <span>
            測驗結果
          </span>
          和
          <span>
            免費英文健檢
          </span>
        </h2>
        <div class="signUp-form">
          <div class="signUp-input">
            <div class="icon">
              <img src="@/assets/event/psychological_test_dizney_characters/form/people.png" alt="姓名">
            </div>
            <input v-model="form.name" type="text" placeholder="姓名 (必填)">
          </div>
          <div class="signUp-input">
            <div class="icon">
              <img src="@/assets/event/psychological_test_dizney_characters/form/phone.png" alt="手機">
            </div>
            <input v-model="form.phone" type="text" placeholder="手機 (必填)">
          </div>
          <div class="signUp-input">
            <div class="icon">
              <img src="@/assets/event/psychological_test_dizney_characters/form/mail.png" alt="信箱">
            </div>
            <input v-model="form.email" type="text" placeholder="信箱 (必填)">
          </div>
          <div class="privacy">
            <input id="privacy" v-model="privacyPolicyCheck" type="checkbox">
            <label for="privacy">
              我同意 WUWOW JUNIOR 線上英文
              <router-link :to="{path:privacyUrl}" target="_blank">隱私權政策</router-link>
            </label>
          </div>
          <button type="button" @click="sendForm()">手刀領取</button>
          <p>
            <span>0800 885 315</span>
            撥打專線，立即有專人為您服務
          </p>
        </div>
      </form>
    </section>
    <section v-if="activeComponent === 'result'" class="result">
      <div class="result-wrap">
        <div class="result-imag">
          <img :src="result[resultImage].web" alt="results">
          <div class="alert-tip">
            <p class="animate__animated animate__pulse animate__infinite	infinite">
              <img src="@/assets/event/psychological_test_dizney_characters/result/finger.png" alt="finger">
              手機平板用戶 請長按儲存圖片
              <br>
              電腦用戶 請右鍵儲存圖片
            </p>
          </div>
        </div>
      </div>
      <div class="result-step">
        <div class="result-title">
          <h2>
            四步驟
            <span>抽一年份Disney+</span>
          </h2>
        </div>
        <ul>
          <li>
            <div class="tag">第一步</div>
            <p>
              儲存並分享你的
              <span>心理測驗</span>
              結果至
              <span>限時動態</span>
            </p>
          </li>
          <li>
            <div class="tag">第二步</div>
            <p>
              限時動態
              <span>公開</span>
              標記
              <span>@wuwow.online</span>
            </p>
          </li>
          <li>
            <div class="tag">第三步</div>
            <p>
              <span>附上</span>
              迪士尼
              <span>心理測驗連結</span>
            </p>
          </li>
          <li>
            <div class="tag">第四步</div>
            <p>
              並
              <span>寫下你的學習方式</span>
              ，例如：我都在
              <br>
              WUWOW JR 學英文！
            </p>
          </li>
        </ul>
        <button type="button" :disabled="showCopySuccess" @click="copyUrl()">
          <span v-if="!showCopySuccess">複製測驗連結</span>
          <span v-if="showCopySuccess">✓複製成功</span>
        </button>
        <input id="copyTextId" type="text" style="display: none;" :value="shareUrl">
      </div>
    </section>
  </main>
</template>

<script>
import q1 from '@/assets/event/psychological_test_dizney_characters/question/Q1-image.png';
import q2 from '@/assets/event/psychological_test_dizney_characters/question/Q2-image.png';
import q3 from '@/assets/event/psychological_test_dizney_characters/question/Q3-image.png';
import q4 from '@/assets/event/psychological_test_dizney_characters/question/Q4-image.png';
import q5 from '@/assets/event/psychological_test_dizney_characters/question/Q5-image.png';
import micky from '@/assets/event/psychological_test_dizney_characters/result/micky.png';
import minnie from '@/assets/event/psychological_test_dizney_characters/result/minnie.png';
import piglet from '@/assets/event/psychological_test_dizney_characters/result/piglet.png';
import winnie from '@/assets/event/psychological_test_dizney_characters/result/winnie-v2.png';

import lioshutanApi from '@/api';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { alertFailedMessage } from '@/utils/sweetAlert.js';

export default {
  name: 'PsychologicalTestDizneyCharacters',

  data() {
    return {
      // banner question form result
      privacyUrl: '/privacy',
      showCopySuccess: false,
      shareUrl: new URL(window.location.href).href,
      activeComponent: 'banner',
      activeQuestion: 'q1',
      question: {
        q1: {
          image: q1,
          title: 'Q1: 你的孩子通常如何面對挑戰？',
          items: [
            {
              id: '1',
              value: 'winnie',
              text: '深思熟慮且冷靜的態度',
            },
            {
              id: '2',
              value: 'micky',
              text: '積極樂觀坦然面對',
            },
            {
              id: '3',
              value: 'minnie',
              text: '從容保持優雅的態度',
            },
          ],
        },
        q2: {
          image: q2,
          title: 'Q2: 你的孩子假日通常是怎麼度過的？',
          items: [
            {
              id: '1',
              value: 'winnie',
              text: ' 喜歡讀繪本/書籍',
            },
            {
              id: '2',
              value: 'piglet',
              text: '出門到處逛逛',
            },
            {
              id: '3',
              value: 'micky',
              text: '與家人或朋友一同度過',
            },
          ],
        },
        q3: {
          image: q3,
          title: 'Q3: 你的孩子都如何面對壓力？',
          items: [
            {
              id: '1',
              value: 'micky',
              text: '樂觀積極勇敢面對',
            },
            {
              id: '2',
              value: 'piglet',
              text: '緊張的尋找同年齡朋友',
            },
            {
              id: '3',
              value: 'minnie',
              text: '向爸爸媽媽求慰藉',
            },
          ],
        },
        q4: {
          image: q4,
          title: 'Q4: 你的孩子最喜歡的故事或電影類型？',
          items: [
            {
              id: '1',
              value: 'winnie',
              text: '溫馨感人的喜劇',
            },
            {
              id: '2',
              value: 'piglet',
              text: '劇情誇張層疊的科幻片',
            },
            {
              id: '3',
              value: 'minnie',
              text: '節慶感十足的家庭片',
            },
          ],
        },
        q5: {
          image: q5,
          title: 'Q5: 你的孩子的個性是？',
          items: [
            {
              id: '1',
              value: 'piglet',
              text: '謹慎小心',
            },
            {
              id: '2',
              value: 'micky',
              text: '活力十足',
            },
            {
              id: '3',
              value: 'winnie',
              text: '溫暖親切',
            },
            {
              id: '4',
              value: 'minnie',
              text: '優雅迷人',
            },
          ],
        },
      },
      answer: [],
      chater: ['winnie', 'piglet', 'micky', 'minnie'],
      questionItemName: ['A', 'B', 'C', 'D'],
      privacyPolicyCheck: true,
      form: {
        name: '',
        phone: '',
        email: '',
      },
      result: {
        micky: {
          web: micky,
          download: micky,
        },
        minnie: {
          web: minnie,
          download: minnie,
        },
        piglet: {
          web: piglet,
          download: piglet,
        },
        winnie: {
          web: winnie,
          download: winnie,
        },
      },
    };
  },
  computed: {
    questionStatus() {
      if (this.answer.length === 4) {
        const data = this.answer.reduce((pre, cur) => {
          if (pre[cur]) {
            pre[cur] += 1;
          } else {
            pre[cur] = 1;
          }
          return pre;
        }, {});
        const entries = Object.entries(data);
        const maxVal = Math.max(...Object.values(data));
        const maxKeys = entries.filter(([key, val]) => val === maxVal).map(([key]) => key);
        if (maxKeys.length === 2) {
          return maxKeys;
        } else {
          return [...maxKeys, ...this.chater.filter((item) => this.answer.includes(item) === false)];
        }
      }
      return [];
    },
    resultImage() {
      if (this.answer.length === 0) {
        return 'micky';
      }
      const resultStatus = this.answer.reduce((acc, cur) => {
        if (acc[cur]) {
          acc[cur] += 1;
        } else {
          acc[cur] = 1;
        }
        return acc;
      }, {});
      console.log(resultStatus);
      return Object.entries(resultStatus).reduce((a, b) => a[1] > b[1] ? a : b)[0];
    },
  },
  methods: {
    fieldCheck(email, phone, formdata) {
      // 只檢查家長姓名
      if (!formdata.name) {
        alertFailedMessage('請留下留下您姓名');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!this.privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },
    nextQuestion(item) {
      window.scrollTo(0, 0);
      if (this.activeQuestion === 'q5') {
        this.answer.push(item.value);
        this.activeComponent = 'form';
        return;
      }
      this.activeQuestion = `q${Number(this.activeQuestion.slice(1)) + 1}`;
      this.answer.push(item.value);
    },
    handleNoteArray(noteArray) {
      // 將 需要放進note的內容組成字串
      let note = '';
      for (let i = 0; i < noteArray.length; i++){
        note += noteArray[i];
        if (noteArray.length !== 1 && i !== noteArray.length - 1) {
          note += ' | ';
        }
        if (i === noteArray.length - 1) {
          return note;
        }
      }
    },
    async sendForm() {
      const fieldChecked = this.fieldCheck(this.form.email, this.form.phone, this.form);
      if (fieldChecked) {
        // let aff_click_id = '';
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const m = searchParams.get('m');
        const noteArray = ['jr_psychological_test_dizney_characters'];

        // 為了給ichannel用的放在備註的gid
        const gid = searchParams.get('gid');
        if (gid) {
          noteArray.push('gid=' + gid);
        }
        // 聯盟網
        // const clickID = searchParams.get('utm_term');
        // if (clickID) {
        //   aff_click_id = clickID;
        // }
        const media = m ? `jr_psychological_test_dizney_characters_${m}` : `jr_psychological_test_dizney_characters`;
        noteArray.push(`測驗結果: ${this.resultImage}`);
        const note = this.handleNoteArray(noteArray);
        const result = await lioshutanApi.common.setMediaSource({
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
          media_source: media,
          note: note,
        });
        if (result.status === 200) {
          this.activeComponent = 'result';
        }
        console.log(result);
      }
    },
    async copyUrl() {
      const copyText = document.getElementById('copyTextId');
      try {
        await navigator.clipboard.writeText(copyText.value);
        console.log('Text copied to clipboard');
        this.showCopySuccess = true;
      } catch (err) {
        console.log('Failed to copy text: ', err);
      } finally {
        setTimeout(() => {
          this.showCopySuccess = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 475px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin xxxl {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin rwd-font($size: 14px, $size-md: 16px, $size-xl: 16px, ) {
  font-size: $size;

  @include md {
    font-size: $size-md;
  }

  @include xl {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @include md {
    line-height: $lh-md;
  }

  @include xl {
    line-height: $lh-xl;
  }
}
@font-face {
  font-family: "GenSenRounded";
  src: url("~@/assets/event/caves-education-authorize/font/GenSenRounded-R.woff2")
    format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "GenSenRounded";
  src: url("~@/assets/event/caves-education-authorize/font/GenSenRounded-M.woff2")
    format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "GenSenRounded";
  src: url("~@/assets/event/caves-education-authorize/font/GenSenRounded-M.woff2")
    format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "GenSenRounded";
  src: url("~@/assets/event/caves-education-authorize/font/GenSenRounded-B.woff2")
    format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "GenSenRounded";
  src: url("~@/assets/event/caves-education-authorize/font/GenSenRounded-H.woff2")
    format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "GenSenRounded";
  src: url("~@/assets/event/caves-education-authorize/font/GenSenRounded-H.woff2")
    format("truetype");
  font-weight: 900;
}

main {
  font-family: GenSenRounded;
  font-weight: normal;
  background: linear-gradient(112.76deg, #91D9FF 10.28%, #91CEFF 25.39%, #8FBDFF 58.43%, #7AA7FF 85.82%, #83ABFF 104.7%);
}

.banner {
  background: url('~@/assets/event/psychological_test_dizney_characters/banner/banner-main-s.png') no-repeat center top / cover;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  padding-top: 15px;

  @include md {
    padding-top: 65px;
  }

  @include md {
    background: url('~@/assets/event/psychological_test_dizney_characters/banner/banner-main-m.png') no-repeat center top / cover;
  }

  @include xxl {
    background: url('~@/assets/event/psychological_test_dizney_characters/banner/banner-main.png') no-repeat center top / cover;
  }

  header {
    ul {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      max-width: 200px;
      margin: 0 auto;

      @include md {
        max-width: 350px;
      }
    }

    li {
      width: 50%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .banner-main {
    margin: 123px auto 0px auto;
    text-align: center;
    max-width: 520px;

    h1 {
      margin: 0;

      span {
        display: inline-block;
        text-indent: 101%;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .banner-title {
      display: inline-block;
      max-width: 277px;

      @include md {
        max-width: 420px;
      }
    }

    .banner-subTitle {
      display: inline-block;
      max-width: 343px;
      margin-bottom: 40px;

      @include md {
        max-width: 100%;
      }
    }

    button {
      appearance: none;
      border: none;
      outline: none;
      background: #033DD6;
      border-radius: 25px;
      border: 3px solid #FFF;
      font-weight: 700;
      font-size: 14px;
      line-height: 155%;
      letter-spacing: 0.84px;
      padding: 11px 0;
      text-align: center;
      width: 100%;
      max-width: 300px;
      color: #fff;
      cursor: pointer;

      &:hover {
        background: lighten(#033DD6, 10%);
      }
    }
  }
}

.question {
  min-height: 100vh;
  padding: 40px 0;
  text-align: center;
  background: url('~@/assets/event/psychological_test_dizney_characters/question/stars.png') no-repeat center bottom / 1067px 200px;

  @include md {
    padding: 80px 0 20px 0;
    background: url('~@/assets/event/psychological_test_dizney_characters/question/stars.png') no-repeat center 120% / 1920px 360px;
  }

  @include xl {
    padding: 80px 0 20px 0;
    background: url('~@/assets/event/psychological_test_dizney_characters/question/stars.png') no-repeat center bottom / contain;
  }

  h2 {
    background-image: url('~@/assets/event/psychological_test_dizney_characters/question/line-t.svg'), url('~@/assets/event/psychological_test_dizney_characters/question/line-b.svg');
    background-size: 24px 7.5px, 24px 7.5px;
    background-position: 0 0, 0 100%;
    background-repeat: repeat-x;
    display: inline-block;
    color: #fff;
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;
    padding: 6px 0;
    margin: 0 0 20px 42px;
    position: relative;

    @include md {
      margin: 0 0 40px 0;
      font-size: 24px;
    }

    @include xl {
      background-size: 48px 15px, 48px 15px;
      margin: 0 0 80px 0;
      padding: 10px 0;
      font-size: 32px;
    }

    >img {
      aspect-ratio: 136/137;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 54px;
      transform: translateX(-80%);

      @include md {
        height: 70px;
      }

      @include xl {
        height: 96px;
      }
    }

    >p {
      margin: 0;
      padding: 4px 6px;
      background: #364356;
      font-size: 16px;
      line-height: 150%;
      font-weight: 900;

      @include md {
        padding: 4px 16px;
        font-size: 24px;
      }

      @include xl {
        font-size: 32px;
      }
    }
  }
}

.question-wrap {
  padding: 0 16px;
  max-width: 425px;
  margin: 0 auto;

  @include md {
    max-width: 600px;
  }
}

.question-main {
  border-radius: 400px 400px 20px 20px;
  border: 5px solid #fff;
  padding: 0 21px 35px 21px;
  background: rgba(255, 255, 255, 0.30);

  @include md {
    border-radius: 400px 400px 40px 40px;
  }

  img {
    max-width: 300px;
    margin: 0 auto;

    @include md {
      max-width: 450px;
      margin: 0 auto 20px auto;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-width: 300px;
    margin: 0 auto;

    li {
      margin-bottom: 20px;
      text-align: center;
      border-radius: 25px;
      border: 3px solid #fff;
      padding: 5px 0;
      color: #364356;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      cursor: pointer;
      user-select: none;
      background: rgba(255, 255, 255, 0.40);

      @media (any-hover: hover) {
        &:hover {
          border: 3px solid #006BCF;
          background: #fff;
        }
      }

      &:active {
        border: 3px solid #006BCF;
        background: #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include md {
        font-size: 18px;
        padding: 10px 0;
      }
    }
  }
}
.form {
    background: url('~@/assets/event/psychological_test_dizney_characters/banner/banner-main-s.png') no-repeat center top / cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    @include md {
      background: url('~@/assets/event/psychological_test_dizney_characters/banner/banner-main-m.png') no-repeat center top / cover;
    }
    @include xxl {
      background: url('~@/assets/event/psychological_test_dizney_characters/banner/banner-main.png') no-repeat center top / cover;
    }
    h2 {
      margin: 0;
    }
    form {
      max-width: 515px;
      width: 100%;
      border-radius: 20px;
      border: 4px solid #fff;
      box-shadow: 0px 0px 20px 0px #D8C8B64D;
      background: rgba(255, 255, 255, 0.80);
      padding: 16px;
      text-align: center;
      @include md {
        padding: 40px;
      }
      h2 {
        font-size: 28px;
        margin-bottom: 40px;
        font-weight: 900;
        line-height: 135%;
        color: #35485A;
        span {
          color: #033DD6;
        }
      }
      button {
        appearance: none;
        border: none;
        outline: none;
        background: #033DD6;
        border-radius: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        letter-spacing: 0.84px;
        padding: 11px 0;
        text-align: center;
        width: 100%;
        color: #fff;
        cursor: pointer;
        margin-bottom: 20px;
        &:hover {
          background: lighten(#033DD6, 10%);
        }
        @include xl {
          margin-bottom: 40px;
        }
      }
      .signUp-form > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 155%;
        letter-spacing: 0.84px;
        color: #35485A;
        span {
          color: #033DD6;
        }
      }
      .signUp-input {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #033DD6;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 27px;
        background: #fff;
        input {
          appearance: none;
          border: none;
          outline: none;
          padding: 8px 6px;
          flex-grow: 1;
          width: 100%;
          font-size: 16px;
          &::placeholder {
            color: #919191;
          }
        }
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-right: 1px solid #033DD6;
        img {
          display: block;
          width: 100%;
          aspect-ratio: 1;
          object-fit: contain;
        }
      }
    }
  }
.privacy {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #505050;
    line-height: 150%;
    margin-top: 47px;
    margin-bottom: 20px;
    cursor: pointer;
    a {
      color: #033DD6;
      font-size: 14px;
      margin-left: 8px;
      text-decoration: none;
    }
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      user-select: none;
    }
    input {
      display: none;
    }
    label::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      margin-right: 4px;
      margin-bottom: 0;
      border: 1px solid #505050;
      background: #fff;
    }
    input:checked~label::before {
        background: #033DD6;
        border: 1px solid #033DD6;
    }
    label::after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 6px;
      height: 10px;
      left: 6px;
      top: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: translate(-23%, -68%) rotate(45deg);
    }
}
.result {
  background: url('~@/assets/event/psychological_test_dizney_characters/result/result.png') no-repeat center top / cover;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  padding: 40px 16px;
  @include md {
    padding-top: 65px;
  }
  @include md {
    background: url('~@/assets/event/psychological_test_dizney_characters/result/result-m.png') no-repeat center top / cover;
  }
  @include xxl {
    background: url('~@/assets/event/psychological_test_dizney_characters/result/result-l.png') no-repeat center top / cover;
  }
}
.result-wrap {
  max-width: 500px;
  margin: 0 auto 40px auto;
  img {
    width: 100%;
  }
  .result-imag {
    position: relative;
    .alert-tip {
      width: 260px;
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      margin: 0;
      img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
    }
    p {
      margin: 0;
      display: flex;
      align-items: flex-start;
    }
  }
}
.result-step {
  border-radius: 20px;
  border: 6px solid #033DD6;
  background: #FFF;
  position: relative;
  padding: 40px 20px;
  max-width: 425px;
  margin: 0 auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    p {
      margin: 0;
    }
    li {
      text-align: center;
      color: #364356;
      margin-bottom: 20px;
      span {
        color: #033DD6;
      }
      @include md {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 20px;
        }
        br {
          display: none;
        }
      }
    }
  }
  .tag {
    padding: 2px 10px 0px 10px;
    border-radius: 20px;
    background: #364356;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    @include md {
      margin-right: 16px;
    }
  }
  button {
    appearance: none;
    border: none;
    outline: none;
    background: #033DD6;
    border-radius: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    letter-spacing: 0.84px;
    padding: 11px 0;
    text-align: center;
    width: 100%;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: lighten(#033DD6, 10%);
    }
    // &:nth-of-type(1) {
    //   margin-bottom: 18px;
    // }
  }
  @include md {
    max-width: 609px;
    padding: 40px 40px 38px 40px;
  }
}
.result-title {
  background: #004B95;
  padding: 5px;
  border-radius: 100px;
  // height: 53px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 300px;
  @include md {
    width: 460px;
  }
  >h2 {
    position: relative;
    background: #FFFBC3;
    border-radius:  96px;
    height: 100%;
    margin: 0;
    padding: 10px 0;
    color: #364356;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    span {
      color: #033DD6;
    }
    &::after {
      content: '';
      position: absolute;
      inset: -2px;
      border: 1px dashed #fff;
      border-radius:  96px;
    }
  }
}
</style>
